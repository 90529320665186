<template>
  <div class="elv-valuation-price-detail-table">
    <div class="elv-financials-valuation-price-detail-table-container">
      <el-table
        ref="tableListRef"
        v-loading="props.tableLoading"
        :data="props.tableData"
        row-key="key"
        border
        stripe
        height="100%"
        class="elv-valuation-price-table"
        header-cell-class-name="elv-valuation-price-table-header__cell"
        header-row-class-name="elv-valuation-price-table-header"
        row-class-name="elv-valuation-price-table-row"
        cell-class-name="elv-valuation-price-table-row__cell"
        :tree-props="{ children: 'list' }"
        default-expand-all
        @row-click="onCellClicked"
      >
        <el-table-column width="40" align="center" type="index" class-name="elv-valuation-price-table-row__cell-index">
          <template #default="{ $index }">
            <p class="elv-sources-table-row__cell-index">
              {{ $index + 1 + (props.pageParams.page - 1) * props.pageParams.limit }}
            </p>
          </template>
        </el-table-column>
        <el-table-column
          width="438"
          class-name="elv-valuation-price-table-row__cell-name"
          :label="t('title.priceName')"
        >
          <template #default="{ row }">
            <IconWithTitleCell :svgName="currentPriceProviderInfo(row).icon" :title="row.name || ''" :svgSize="18" />
            <span v-if="row.isDefault" class="elv-valuation-price-table-row__cell-name-default">{{
              t('common.default')
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          width="130"
          class-name="elv-valuation-price-table-row__cell-method"
          :label="t('title.priceMethod')"
        >
          <template #default="{ row }">
            <span :class="`elv-valuation-price-table-row__cell-method-${row.type}`">
              {{ row?.type == 'CSV' ? 'CSV' : 'API' }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          width="200"
          align="left"
          class-name="elv-valuation-price-table-row__cell-date-range"
          :label="t('common.dateRange')"
        >
          <template #default="{ row }">
            <DataTimeFromToCell v-if="row?.fromDatetime" :fromTime="row.fromDatetime" :endTime="row.toDatetime" />
            <IconWithTitleCell v-else title="-" icon="" titleFontFamily="Barlow" :titleWeight="400" />
          </template>
        </el-table-column>
        <el-table-column
          width="130"
          align="left"
          class-name="elv-valuation-price-table-row__cell-quote"
          :label="t('valuation.price.quote')"
        >
          <template #default="{ row }">
            <div>
              <IconWithTitleCell
                :icon="row?.quoteCurrencyMap?.logo || ''"
                :title="row.quoteCurrencyMap?.showSymbol || '-'"
                :title-weight="400"
              />
            </div>
          </template>
        </el-table-column>
        <el-table-column
          width="130"
          align="left"
          class-name="elv-valuation-price-table-row__cell-assets"
          :label="t('report.Assets')"
        >
          <template #default="{ row }">
            <div>{{ formatNumber(Number(row.currencyCount), 2) }}</div>
          </template>
        </el-table-column>
        <el-table-column class-name="elv-valuation-price-table-row__cell-empty"> </el-table-column>
      </el-table>
    </div>
    <elv-pagination
      v-if="tableData.length"
      class="elv-journals-list-pagination"
      size-popper-class="elv-journals-pagination__sizes-popper"
      :limit="props.pageParams.limit"
      :current-page="props.pageParams.page"
      :total="props.pageParams.total"
      @current-change="onChangePage"
      @size-change="onChangePageSize"
    />
  </div>
</template>
<script lang="ts" setup>
import { formatNumber } from '@/lib/utils'
import { PriceProviderItemType } from '#/ValuationTypes'
import DataTimeFromToCell from '@/pages/Financials/Project/components/DateTimeFromToCell.vue'
import { priceProviderTypeOptions } from '@/config/valuation'
import IconWithTitleCell from '@/components/Base/Table/IconWithTitleCell.vue'

const props = defineProps<{
  tableData: any[]
  tableLoading: boolean
  pageParams: any
}>()

const emit = defineEmits(['onChangePage', 'onChangePageSize', 'onClickTableCell'])

const { t } = useI18n()

const currentPriceProviderInfo = computed(() => {
  return (row: PriceProviderItemType) => {
    const priceProviderInfo = {
      icon: priceProviderTypeOptions.find((item) => item.value === row.type)?.svgName || 'csv-file'
    }
    return priceProviderInfo
  }
})

/**
 * 分页器页码变化触发
 * @param page 页码
 */
const onChangePage = (page: number) => {
  emit('onChangePage', page)
}

/**
 * 分页器每页条数变化触发
 * @param limit 每页条数
 */
const onChangePageSize = (limit: number) => {
  emit('onChangePageSize', limit)
}

/**
 * 打开价格源详情
 * @param row 点击的行数据
 */
const onCellClicked = (row: any) => {
  emit('onClickTableCell', row)
}
</script>
<style lang="scss">
.elv-valuation-price-detail-table {
  width: 100%;
  height: calc(100% - 50px);
  position: relative;

  .elv-financials-valuation-price-detail-table-container {
    width: 100%;
    height: calc(100% - 54px);
    position: relative;
  }

  .elv-valuation-price-table {
    --el-table-border-color: #d4dce5;
    --el-table-border: 1px solid #edf0f3;
    --el-fill-color-lighter: #f9fafb;
    width: 100%;
    box-sizing: border-box;
    border: 1px solid #d4dce6;
    border-right: 0px;
    border-left: 0px;
    border-bottom-width: 1px;

    .el-table__inner-wrapper {
      width: 100%;

      &::after,
      &::before {
        width: 0;
        height: 0;
      }
    }

    &.el-table--border {
      border-bottom-color: #edf0f3;

      &::after,
      &::before {
        width: 0;
        height: 0;
      }
    }

    .el-table__body-wrapper {
      height: 616px;

      .el-scrollbar__view {
        width: 100%;
        margin-bottom: 0px !important;
      }
    }

    .el-table__border-left-patch {
      width: 0;
    }

    .elv-valuation-price-table-header {
      background: #eef4fb;

      .elv-valuation-price-table-header__cell {
        background: #eef4fb;
        height: 36px;
        box-sizing: border-box;
        border-right-color: #e4e7eb;
        border-bottom-color: #ced7e0;
        padding: 0px;

        &:last-child {
          border-right: 0px;
        }

        &.is-center {
          .cell {
            justify-content: center;
          }
        }

        &.is-right .cell {
          justify-content: flex-end;
        }

        .cell {
          font-family: 'Plus Jakarta Sans';
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 15px;
          color: #666d74;
          padding: 0 10px;
          display: flex;
          align-items: center;
          justify-items: center;
        }
      }
    }

    .elv-valuation-price-table-row__cell {
      padding: 0;
      height: 42px;
      cursor: pointer;

      &:last-child {
        border-right: 0px;
      }

      &.is-right .cell {
        text-align: right;
      }

      &.is-center .cell {
        text-align: center;
      }

      &.is-number .cell {
        font-family: 'Barlow';
        font-weight: 500;
      }

      .cell {
        padding: 0 10px;
        font-family: 'Plus Jakarta Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        color: #0e0f11;
        line-height: 16px;

        .el-table__indent {
          padding-left: 0px !important;
          border-left: 0px;
        }

        .el-table__placeholder {
          width: 40px;
          box-sizing: border-box;
          height: 100%;
          padding-left: 40px !important;
          border-left: 0px;
        }

        span {
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          border-left: 1px solid #edf0f3;
          white-space: nowrap;
        }

        .el-table__expand-icon {
          width: 40px;
          height: 100%;
          display: none;
          align-items: center;
          justify-content: center;
          box-sizing: border-box;
          margin-right: 0px;
        }
      }

      &.elv-valuation-price-table-row__cell-index .cell {
        color: #aaafb6;
        font-family: 'Barlow';
        font-size: 11px;
        font-style: normal;
      }

      &.elv-valuation-price-table-row__cell-name .cell {
        display: flex;
        align-items: center;

        .elv-base-cell-icon-title-svg {
          border-radius: 0px;
        }

        .empty-icon {
          width: 18px;
          height: 18px;
          margin-right: 8px;
        }

        .elv-valuation-price-table-row__cell-name-default {
          padding-top: 2px;
          color: #838d95;
        }
      }

      &.elv-valuation-price-table-row__cell-method .cell {
        span {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 22px;
          width: fit-content;
          padding: 0px 8px;
          border-radius: 3px;
          border: 1px solid #a990dd;
          background-color: rgba($color: #a990dd, $alpha: 0.2);
          color: #a990dd;
          font-family: 'Plus Jakarta Sans';
          font-size: 11px;
          font-weight: 400;

          &.elv-valuation-price-table-row__cell-method-CSV {
            border: 1px solid #fda27e;
            background-color: rgba($color: #fda27e, $alpha: 0.2);
            color: #fda27e;
          }
        }
      }

      &.elv-valuation-price-table-row__cell-assets .cell {
        font-weight: 500;
        font-family: 'Barlow';
      }

      .elv-valuation-price-table-row__cell-activity {
        font-weight: 500;
      }

      &.el-table-column--selection {
        .cell {
          width: 100%;
          padding: 0px;
          align-items: center;
          justify-content: center;
        }
      }

      .elv-valuation-price-table-row__cell-desc {
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        word-break: break-word;
      }

      .elv-valuation-assets-cell__status {
        display: flex;
        height: 22px;
        width: fit-content;
        padding: 0px 8px;
        box-sizing: border-box;
        align-items: center;
        gap: 4px;
        border-radius: 14px;
        border: 1px solid #d0d4d9;
        background: #edf0f3;
        color: #636b75;

        svg {
          margin-right: 4px;
        }

        &.is-approved {
          border-color: #a9dc78;
          background: #f3fceb;
          color: #50970b;
        }
      }

      .elv-valuation-price-table-row__cell-button {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          cursor: pointer;
          fill: #838d95;

          &.is-disabled {
            cursor: not-allowed !important;
            fill: #edf0f3 !important;
          }

          &:first-of-type {
            margin-right: 8px;
          }

          &:hover {
            fill: #1e2024;
          }
        }
      }
    }

    .el-table__footer-wrapper {
      .el-table__footer {
        height: 42px;

        .cell.elv-valuation-price-table-row__total {
          color: #636b75;
          font-family: 'Plus Jakarta Sans';
          font-size: 11px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }

        .cell.elv-valuation-price-table-row__amount {
          color: #0e0f11;
          font-family: 'Barlow';
          font-size: 13px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          text-transform: uppercase;

          p {
            color: #838d95;
            font-family: 'Barlow';
            font-size: 11px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-transform: none;
          }
        }
      }
    }
  }

  .elv-base-cell-icon-title {
    padding: 12px 0px;
  }
}
</style>
