<template>
  <el-drawer
    v-model="isShowDetailDrawer"
    :show-close="false"
    modal-class="elv-valuation-price-detail-overlay"
    class="elv-valuation-price-detail"
    :before-close="onCheckDrawerStatus"
    :size="400"
  >
    <template #header="{ titleId }">
      <h4 :id="titleId" class="elv-valuation-price-detail-header__title">
        {{ t('report.priceProvider') }}
      </h4>
      <SvgIcon
        name="close"
        width="24"
        height="24"
        class="elv-valuation-price-detail-header__close"
        @click="onCheckDrawerStatus"
      />
    </template>
    <el-scrollbar v-loading="loading">
      <div class="elv-valuation-price-detail-info-detail-content">
        <div class="elv-valuation-price-detail-info__title">
          <IconWithTitleCell
            :svgName="priceDetailInfo.icon"
            :title="props.currentData?.name || ''"
            titleSize="24px"
            :svgSize="24"
          />
        </div>
        <ul>
          <li class="elv-valuation-price-detail-info__from">
            <span class="elv-valuation-price-detail-info__from-title">{{ t('common.method') }} </span>
            <div class="elv-valuation-price-detail-info__from-content normal">
              {{ props.currentData?.type === 'CSV' ? 'CSV' : 'API' }}
            </div>
          </li>
          <li class="elv-valuation-price-detail-info__from">
            <span class="elv-valuation-price-detail-info__from-title">{{ t('valuation.price.quote') }} </span>
            <div class="elv-valuation-price-detail-info__from-content normal">
              <IconWithTitleCell
                :icon="priceDetailInfo.showSymbol"
                :title="priceDetailInfo.title"
                :titleWeight="500"
                titleFontFamily="Barlow"
              />
            </div>
          </li>
          <li class="elv-valuation-price-detail-info__from">
            <span class="elv-valuation-price-detail-info__from-title">{{ t('valuation.price.startDataTime') }} </span>
            <div class="elv-valuation-price-detail-info__from-content normal">
              <DateTimeFromToCell
                v-if="detailPriceData?.fromDatetime"
                :fromTime="detailPriceData.fromDatetime"
                format="YYYY/MM/DD HH:mm"
              ></DateTimeFromToCell>
              <IconWithTitleCell v-else title="-" icon="" title-font-family="Barlow" :title-weight="500" />
            </div>
          </li>
          <li class="elv-valuation-price-detail-info__from">
            <span class="elv-valuation-price-detail-info__from-title">{{ t('valuation.price.latestDataTime') }} </span>
            <div class="elv-valuation-price-detail-info__from-content normal">
              <DateTimeFromToCell
                v-if="detailPriceData?.toDatetime"
                :fromTime="detailPriceData.toDatetime"
                format="YYYY/MM/DD HH:mm"
              ></DateTimeFromToCell>
              <IconWithTitleCell v-else title="-" icon="" title-font-family="Barlow" :title-weight="500" />
            </div>
          </li>
          <li class="elv-valuation-price-detail-info__from">
            <span class="elv-valuation-price-detail-info__from-title">{{ t('common.timezone') }} </span>
            <div class="elv-valuation-price-detail-info__from-content time-zone">
              {{ currentTimeZone ? `${currentTimeZone.area} (${currentTimeZone.timezone})` : '-' }}
            </div>
          </li>
          <li class="elv-valuation-price-detail-info__from">
            <span class="elv-valuation-price-detail-info__from-title">{{ t('report.Assets') }} </span>
            <div class="elv-valuation-price-detail-info__from-content normal assets">
              {{ formatNumber(Number(detailPriceData.currencyCount), 2) }}
            </div>
          </li>
        </ul>
        <div
          v-if="detailPriceData.type === 'CSV' || detailPriceData.priceSources?.length"
          class="elv-valuation-price-detail-info__data-source"
        >
          <p class="elv-valuation-price-detail-info__data-source-title">
            {{ t('valuation.price.dataSources') }}
          </p>
          <div class="elv-valuation-price-detail-info__data-source-content">
            <div
              v-for="item in detailPriceData.priceSources"
              :key="item.priceSourceId"
              class="elv-valuation-price-detail-info__data-source-item"
            >
              <SvgIcon :name="priceDetailInfo.icon" :width="24" :height="24" />
              <p>
                <span class="file-name">{{
                  priceDetailInfo.methodType !== 'CSV' ? t(priceDetailInfo.methodType) : item.priceFile?.name || '-'
                }}</span>
                <span v-if="item.type === 'CSV'" class="bottom-content">
                  <span v-if="item.syncStatus === 'FINISHED'" class="bottom-content-finish">
                    <span v-if="item.dataCount">Data：{{ item.dataCount }}</span>
                    <span v-if="item.latestUpdatedAt">
                      <span class="line" /> {{ t('common.updatedOn') }}:{{
                        dayjs(item.latestUpdatedAt)
                          .tz(entityStore.entityDetail?.timezone ?? 'UTC')
                          .format('YYYY/MM/DD HH:mm:ss')
                      }}</span
                    >
                  </span>
                  <span v-else-if="item.syncStatus === 'DOING'" class="bottom-content-sync">
                    <img src="@/assets/img/reports/sources-sync-loading.png" alt="loading" />
                    <span>{{ t('common.syncing') }}</span>
                  </span>
                  <span v-else class="bottom-content-failed">
                    <SvgIcon class="failed" name="warning" width="16" height="16" />
                    <span>{{ t('common.syncFailed') }}</span>
                  </span>
                </span>
                <span v-else class="bottom-content">
                  <span class="bottom-content-link-address">{{
                    item.type === 'API_EXCHANGE_RATE' ? 'exchangeratesapi.io' : 'coingecko.com'
                  }}</span>
                  <span v-if="item.latestUpdatedAt" class="line" />
                  <span v-if="item.latestUpdatedAt"
                    >{{ t('common.syncedOn') }}:{{
                      dayjs(item.latestUpdatedAt)
                        .tz(entityStore.entityDetail?.timezone ?? 'UTC')
                        .format('YYYY/MM/DD HH:mm:ss')
                    }}</span
                  >
                </span>
              </p>
            </div>
          </div>
          <div v-if="detailPriceData.type === 'CSV'" class="elv-valuation-price-detail-info__data-source-button">
            <el-button class="elv-confirm-info-footer__add-button" @click="openUploadCsvFileDialog"
              ><span class="symbol">+</span> {{ t('button.addData') }}</el-button
            >
          </div>
        </div>
      </div>
    </el-scrollbar>
  </el-drawer>
  <UploadCSVDialog
    ref="uploadCSVDialogRef"
    :title="t('title.uploadPriceData')"
    templateSlug="price-source"
    templateUrl="file/csv/Elven%20Prive%20Provider%20Import%20Template.csv"
    :noPermission="
      ['MEMBER', ''].includes(currentEntityPermission?.role) && !currentEntityPermission?.entityAccount?.create
    "
    :submitText="t('button.upload')"
    @onSaveImport="onSaveImport"
    @onResetList="resetList"
  />
</template>
<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import { isEmpty } from 'lodash-es'
import { emitter } from '@/lib/mitt'
import timezone from '@/config/timezone'
import { formatNumber } from '@/lib/utils'
import ValuationApi from '@/api/ValuationApi'
import { dayjs, ElMessage } from 'element-plus'
import { useEntityStore } from '@/stores/modules/entity'
import { PriceProviderItemType } from '#/ValuationTypes'
import { priceProviderTypeOptions } from '@/config/valuation'
import IconWithTitleCell from '@/components/Base/Table/IconWithTitleCell.vue'
import UploadCSVDialog from '@/pages/Financials/Project/components/UploadCSVDialog.vue'
import DateTimeFromToCell from '@/pages/Financials/Project/components/DateTimeFromToCell.vue'

const props = defineProps({
  currentData: {
    type: Object,
    default: () => {}
  }
})

const { t } = useI18n()
const route = useRoute()
const entityStore = useEntityStore()
const uploadCSVDialogRef = useTemplateRef('uploadCSVDialogRef')

const loading = ref(false)
const isShowDetailDrawer = ref(false)
const detailPriceData = ref<PriceProviderItemType>({} as any)

const entityId = computed(() => {
  return String(route.params?.entityId as string)
})

const currentEntityPermission = computed(() => {
  return entityStore.entityPermission()
})

const priceDetailInfo = computed(() => {
  const assetData = { icon: '', name: '', title: '', showSymbol: '', methodType: '' }
  assetData.icon = priceProviderTypeOptions.find((item) => item.value === detailPriceData.value?.type)?.svgName || ''
  assetData.methodType =
    priceProviderTypeOptions.find((item) => item.value === detailPriceData.value?.type)?.label || ''
  assetData.name = detailPriceData.value?.name || ''
  assetData.title = detailPriceData.value?.quoteCurrencyMap?.showSymbol || '-'
  assetData.showSymbol = detailPriceData.value?.quoteCurrencyMap?.logo || ''
  return assetData
})

const currentTimeZone = computed(() => {
  return timezone.find((item) => item.area === detailPriceData.value?.timezone)
})

/**
 * 切换价格源详情弹窗显示状态
 */
const onCheckDrawerStatus = () => {
  isShowDetailDrawer.value = !isShowDetailDrawer.value
}

/**
 * 打开导入价格源数据弹窗
 */
const openUploadCsvFileDialog = () => {
  uploadCSVDialogRef.value?.onCheckUploadCSVDialog()
}

/**
 * 获取价格源详情数据
 */
const fetchPriceDetail = async () => {
  loading.value = true
  try {
    detailPriceData.value = props.currentData as any
    const info: any = await ValuationApi.getPriceProviderDetail(entityId.value, props.currentData?.priceProviderId)
    detailPriceData.value = info?.data
  } catch (error) {
    console.error(error)
  } finally {
    loading.value = false
  }
}

/**
 * 保存价格源CSV数据
 * @param params 导入数据参数
 */
const onSaveImport = async (params: any) => {
  try {
    const data = {
      entityFileId: params.entityFileId
    }
    const res = await ValuationApi.savePriceProviderCSVData(
      entityId.value,
      detailPriceData.value?.priceProviderId,
      data
    )
    uploadCSVDialogRef.value?.onSuccessImport()
    await fetchPriceDetail()
    return Promise.resolve(res)
  } catch (error: any) {
    ElMessage.error(error.message)
    return Promise.reject(error)
  } finally {
    uploadCSVDialogRef.value?.onFinallyImport()
  }
}

/**
 * 更新价格源table列表数据
 */
const resetList = () => {
  emitter.emit('resetAccountList')
}

watch(
  [() => isShowDetailDrawer.value, () => props.currentData],
  () => {
    if (isShowDetailDrawer.value && !isEmpty(props.currentData)) {
      fetchPriceDetail()
    }
  },
  {
    immediate: true,
    flush: 'post'
  }
)

defineExpose({
  onCheckDrawerStatus
})
</script>
<style lang="scss" scoped>
.elv-valuation-price-detail {
  .elv-valuation-price-detail-header__title {
    font-family: 'Plus Jakarta Sans';
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #0e0f11;
    margin: 0;
  }

  .elv-valuation-price-detail-header__close {
    &:hover {
      cursor: pointer;
    }
  }
}
</style>

<style lang="scss">
.elv-valuation-price-detail {
  .el-drawer__header {
    padding: 26px 0px 18px;
    margin: 0 24px;
    border-bottom: 1px solid #edf0f3;
    height: 64px;
    box-sizing: border-box;
  }

  .el-drawer__body {
    padding: 16px 0px;
    margin-bottom: 16px;
  }

  .el-drawer__footer {
    text-align: left;
    padding: 0px 24px 24px;
  }

  .el-scrollbar__view {
    padding: 0 24px;
  }

  .elv-valuation-price-detail-info-detail-content {
    margin-bottom: 10px;
    position: relative;

    .elv-valuation-price-detail-info__title {
      display: flex;
      width: 100%;

      .elv-base-cell-icon-title {
        padding-left: 0px;

        svg {
          border-radius: 0px;
        }
      }
    }
  }

  ul {
    width: 100%;
    margin: 16px 0;
    border-top: 1px solid #edf0f3;
    display: flex;
    flex-wrap: wrap;

    li {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      width: 50%;
      height: 54px;
      box-sizing: border-box;
      border-bottom: 1px solid #edf0f3;

      &:nth-child(2n) {
        padding-left: 8px;
        border-left: 1px solid #edf0f3;
      }

      span {
        font-family: 'Plus Jakarta Sans';
        font-weight: 400;
        font-size: 11px;
        line-height: 14px;
        color: #838d95;
      }
    }

    .elv-valuation-price-detail-info__from {
      .elv-valuation-price-detail-info__from-title {
        display: flex;
        align-items: center;

        svg {
          margin-left: 6px;
          cursor: pointer;
          fill: #aaafb6;

          &:hover {
            fill: #1e2024;
          }
        }
      }

      .elv-valuation-price-detail-info__from-content {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-top: 2px;
        padding: 0px;
        height: 23px;
        box-sizing: border-box;
        font-size: 13px;
        font-weight: 500;

        span {
          font-family: 'Plus Jakarta Sans';
          font-weight: 500;
          font-size: 13px;
          line-height: 16px;
          color: #0e0f11;
          max-width: 98px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        img {
          width: 14px;
          height: 14px;
          display: block;
          margin-right: 4px;
        }

        .elv-base-cell-icon-title {
          padding: 0px;
        }

        .elv-base-cell-vertical-text {
          div {
            text-align: left;
          }
        }

        .elv-cell-date-time-main-top {
          font-weight: 500;
        }

        &.time-zone,
        &.assets {
          font-family: 'Barlow';
        }
      }
    }
  }

  .elv-valuation-price-detail-info__data-source {
    border-radius: 5px;
    background: #f9fafb;
    padding: 8px;

    .elv-valuation-price-detail-info__data-source-title {
      height: 40px;
      display: flex;
      align-items: center;
      font-size: 16px;
      font-weight: 600;
      border-bottom: 1px solid #edf0f3;
    }

    .elv-valuation-price-detail-info__data-source-content {
      border-bottom: 1px solid #edf0f3;

      .elv-valuation-price-detail-info__data-source-item {
        margin-top: 8px;
        padding: 8px 0px;
        display: flex;
        align-items: center;
        position: relative;

        p {
          display: flex;
          flex-direction: column;
          max-width: calc(100% - 40px);
          margin-left: 8px;
          position: relative;

          .file-name {
            color: #1e2024;
            font-size: 14px;
            font-weight: 500;
          }

          .bottom-content {
            display: flex;
            align-items: center;
            white-space: nowrap;
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            color: #838d95;
            font-size: 11px;
            font-weight: 400;
            line-height: 12px;
            margin-top: 2px;

            .line {
              display: flex;
              width: 1px;
              height: 11px;
              background-color: #dde1e6;
              margin: 0px 8px;
              margin-top: 2px;
            }

            .bottom-content-sync,
            .bottom-content-failed {
              display: flex;
              align-items: center;
              font-size: 12px;
              margin-top: 5px;

              span {
                padding-bottom: 1px;
              }

              svg,
              img {
                margin-right: 6px;
              }

              img {
                width: 16px;
                height: 16px;
                animation: loading-rotate 2s linear infinite;
              }
            }

            .bottom-content-finish {
              display: flex;
              align-items: center;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;

              span {
                display: flex;
                align-items: center;
              }
            }

            .bottom-content-link-address {
              line-height: 14px;
            }
          }
        }
      }
    }

    .elv-valuation-price-detail-info__data-source-button {
      margin-top: 8px;
      height: 40px;
      display: flex;
      align-items: center;

      .elv-confirm-info-footer__add-button {
        border: none;
        background: none;
        display: flex;
        align-items: center;
        color: #2f63eb;
        font-size: 14px;
        font-weight: 500;
        padding: 8px 0px;

        .symbol {
          color: #7596eb;
          font-size: 20px;
          margin-right: 8px;
          padding-bottom: 2px;
        }
      }
    }
  }
}
</style>
